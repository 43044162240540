$primary-color: #056aec;
$black-color: #222528;
$placeholder-color: #8897ad;
$text-color: #313957;
$green-color: #34b53a;
$dark-light: #222528;
$bg-color: #efefef;
$yellow-color: #f7ce46;
$lightyellow-color: #4c472e;
$form-bg: #f7fbff;
$form-border: #d4d7e3;
$white-color: #ffffff;
$red-color: #ff1612;
$th-color: #484964;
$grey-color: #747474;
$grey-color1: #9c9c9c;
$noti_color: #424242;
$noti_para_color: #757575;
$dark_color: #2b2b2b;
