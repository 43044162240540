@import "./Variables.scss";
.btn-outline-primary,
.btn-primary {
  height: 3.25em;
  width: 100%;
  border-radius: 0.75em;
  font-weight: 600;
}

a.btn-primary {
  background-color: $primary-color;
  border-color: $primary-color;
  color: $white-color;
  line-height: 2.4em;
  font-weight: 400;
  height: 3.25em;
  font-size: 1em;
  width: 100%;
  &:hover,
  &:active {
    background-color: transparent;
    border-color: $primary-color;
    color: $primary-color;
  }
}

.btn-primary {
  background-color: $primary-color;
  border-color: $primary-color;
  color: $white-color;
  line-height: 2em;
  font-weight: 400;
  height: 3em;
  font-size: 1em;
  width: 100%;
  &:hover,
  &:active {
    background-color: transparent;
    border-color: $primary-color;
    color: $primary-color;
  }
}

.scree_btn {
  display: flex;
  align-items: flex-end;
  gap: 7px;
  .btn-primary {
    width: 8em;
    height: 3em;
    margin: 0 1em;
  }
  .form-pad {
    flex-grow: 1;
    margin-bottom: 0 !important;
  }
}
