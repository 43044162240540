@media only screen and (min-width: 1700px) {
}

@media only screen and (min-width: 1400px) {
}

@media only screen and (max-width: 1280px) {
  .login_box {
    position: relative;

    .loginBody {
      padding: 1.5em 1em;
    }
  }
}

@media only screen and (max-width: 1250px) {
  .req_flex {
    font-size: 0.9em;
  }
  .plan_sec {
    .plan_box {
      .card {
        flex-basis: 28%;
      }
      .w30 {
        flex-basis: 33%;
      }
    }
  }
}

@media only screen and (max-width: 1080px) {
  .req_flex {
    font-size: 0.8em;
  }

  .navbar {
    .navbar-nav {
      .nav-link {
        .badge {
          left: 5px;
          right: auto;
        }
      }
    }
    .useimg {
      margin-left: 0;
    }
  }

  .contact_us {
    .wi50 {
      width: 100%;
    }
    .flex_get {
      padding: 3em 0;
    }
    .get_bgimg {
      &.wi50 {
        display: none;
      }
    }
    .fixHeight {
      padding-left: 4.5em;
      padding-right: 4.5em;
    }
  }

  ._1Lo2h ._2Jtxm ._1hzhf {
    font-size: 0.7em;
    width: 100%;
  }

  ._53Ji7 {
    padding: 2rem 0;
  }

  .chartBox {
    margin-top: 1em;
  }

  .navbar {
    .navbar-nav {
      .nav-link {
        margin-right: 0.9em;
      }
    }
  }
  .plan_box {
    .card {
      flex-basis: 44%;
      margin-bottom: 1em;
    }
  }
}

@media only screen and (max-width: 991px) {
  .plan_sec {
    .plan_box {
      .card {
        flex-basis: 100%;
      }
      .w30 {
        flex-basis: 33%;
      }
    }
  }

  .card-boxx .repeat_sec div.wfx
  {
    width: 100%;
  }

  .overview_sec {
    .scheading {
      font-size: 1em;
      margin-bottom: 1em;
    }
  }

  .tab_link a {
    margin-bottom: 1em;
  }

  .plan_sec {
    .plan_box {
      .w30 {
        flex-basis: 49%;
      }
    }
  }
  .navbar {
    .navbar-toggler {
      border-color: $white-color;
    }
    .navbar-toggler {
      &:focus {
        box-shadow: none;
      }
    }
    .navbar-toggler-icon {
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
    }
    .navbar-nav {
      .nav-link {
        margin-right: 0;
      }
    }
  }
  .padTab {
    margin: 10px 0;
  }
  .login_box {
    position: relative;
    .logo1 {
      position: absolute;
      width: 7.188em;
      height: 1.938em;
      object-fit: contain;
      top: 1em;
      right: 1em;
    }
    .loginBody {
      padding: 1.5em 1em;
    }
  }
}

@media only screen and (max-width: 767px) {
  /* Light Mode & Dark Mode Css */
  .banner_sec {
    font-size: 0.7em;
    .btn-primary {
      margin-bottom: 1.5em;
    }
  }

  .slider_new {
    .carousel-caption {
      width: 70%;
      h3 {
        font-size: 1.8em;
      }
    }
  }

  .mobileTop {
    margin-top: 1em;
  }

  .deleteBbox {
    svg {
      margin-left: 0.5em;
    }
  }

  .tab_link {
    a {
      margin-bottom: 1em;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .plan_box {
    .card {
      flex-basis: 100%;
    }
    .w10 {
      flex-basis: 100%;
      margin-bottom: 1em;
    }
  }

  .card-boxx {
    .repeat_sec {
      &.repeat_bbbox {
        flex-direction: column;
      }
      div {
        flex-grow: 1;
        margin: 0;
        width: 100%;
      }
    }
  }
  .scree_btn {
    justify-content: center;
    flex-direction: column;
    .form-pad {
      width: 100%;
    }
    .btn-primary {
      width: 100%;
      height: 3em;
      margin: 1em 0;
    }
    .btnBack {
      width: 100%;
    }
  }

  ._2pGos {
    padding: 0.75rem 2.5rem;
  }

  .cardBox {
    .card-header {
      justify-content: space-between;
      padding-left: 1em;
      padding-right: 1em;
      font-size: 13px;
      .form-select {
        font-size: 1em;
      }
    }
    .card-body {
      padding-left: 1em;
      padding-right: 1em;
    }
  }

  .btnPad {
    font-size: 1em;
  }

  .requestFlex {
    font-size: 0.8em;
    flex-direction: column;

    div {
      &.wmedbox {
        width: auto;
      }
      width: 100%;
      justify-content: center;
      &.mtt20 {
        margin-top: 0;
      }
      .btnPad {
        width: 11em;
        &.wmed {
          width: 100%;
        }
      }
      .form-select {
        width: 11em;
        height: 43px;
      }
    }
  }

  ._1hzhf {
    display: none;
  }

  ._53Ji7 {
    padding: 1em 0;
  }

  .blog_sec {
    .blog_card {
      margin-bottom: 1.5em;
      text-align: center;

      p {
        max-width: 100%;
      }
    }
  }

  .footer {
    text-align: center;
    ul {
      justify-content: center;
    }
    .expFlex {
      justify-content: center;
    }
    .copyright {
      margin-top: 1.5em;
      text-align: center;
    }
  }

  .howitwork_sec {
    p {
      margin-bottom: 2em !important;
      max-width: 100em;
    }
  }

  .plan_sec {
    .plan_box {
      .w30 {
        flex-basis: 100%;
      }
    }
  }

  .aboutlanding_sec {
    font-size: 0.7em;
  }
  /* End */

  .contact_us {
    .fixHeight {
      padding-left: 1.5em;
      padding-right: 1.5em;
    }
  }

  .widthSmall {
    --bs-gutter-x: 2.5rem;
  }

  .scheading {
    font-size: 0.9em;
  }

  .req_flex {
    flex-direction: column;
  }

  .cardUpgrade {
    .card-body {
      flex-direction: column;
    }
  }

  .cardFlex_box {
    .card_box_flex {
      flex-wrap: wrap;
      .det_main {
        flex-basis: 100%;
        border-bottom: 1px solid #ccc;
        margin-bottom: 1em;
        &:last-child {
          border-bottom: 0;
          margin-bottom: 0;
        }
        .det_box {
          &.mb-5 {
            margin-bottom: 1em !important;
          }
        }
      }
    }
  }
  .hideloginimg {
    display: none;
  }
  .login_box {
    position: relative;
    font-size: 0.9em;
    .logo1 {
      bottom: 0.7em;
      right: 1em;
      top: auto;
    }
    .loginBody {
      padding: 0 0 2em 0;
    }
  }
}

@media only screen and (max-width: 565px) {
  .banner_sec {
    h1 {
      font-size: 3.5em;
    }
  }
}
