@import url("https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,400;9..40,500;9..40,600;9..40,700;9..40,800&display=swap");

@import "./component/sass/Variables.scss";
@import "./component/sass/CustomButtons.scss";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body,
html {
  font-size: 16px;
  font-family: "DM Sans", sans-serif;
}

body {
  overflow-x: hidden;
}

a {
  text-decoration: none;
  &:hover {
    color: $black-color;
  }
}

.widthSmall {
  --bs-gutter-x: 8.5rem;
}

.table-bordered > :not(caption) > * > * {
  border-width: 0;
  font-size: 0.8em;
}

.table thead tr th {
  color: $th-color;
}

.heading {
  font-size: 1.5em;
  font-weight: 700;
}

.navbar {
  &.bg-primary {
    background-color: $black-color !important;
  }
  padding: 0.4em 0.2em;
  .navbar-brand {
    img {
      height: 84px;
      object-fit: contain;
    }
  }
  .navbar-nav {
    .nav-link {
      padding-left: 0.8em;
      padding-right: 0.8em;
      margin-right: 1.8em;
      color: $white-color;
      transition: all ease 0.3s;
      border-radius: 5px;
      position: relative;
      &:last-child {
        margin-right: 0;
      }
      span {
        padding-left: 0.5em;
      }
      transition: all ease 0.3s;
      svg {
        path {
          transition: all ease 0.3s;
        }
      }
      &:hover,
      &.active {
        color: $yellow-color;
        background-color: $lightyellow-color;
        svg {
          path {
            fill: $yellow-color;
          }
        }
      }
      .badge {
        position: absolute;
        top: 7px;
        right: -1px;
        font-size: 0.6em;
        &.bg-primary {
          background-color: $red-color !important;
        }
      }
    }
  }
  .useimg {
    margin-left: 0.5em;
    width: 35px;
    height: 35px;
    border-radius: 100px;
  }
}

.uploadBox {
  position: relative;
  border: 1px dashed #d4d4d4;
  padding: 1em;
  height: 115px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transition: all ease 0.3s;
  cursor: pointer;
  input {
    position: absolute;
    width: 100%;
    height: 100%;
    cursor: pointer;
    opacity: 0;
  }
  p {
    margin: 0;
  }
  svg {
    font-size: 3em;
    color: $primary-color;
  }
  &:hover {
    border-color: $primary-color;
  }
}


.uploadBox {
  .file {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .file {
    width: 100%;
    > input[type="file"] {
      display: none;
    }
    label {
      font-size: 1rem;
      flex-direction: column;
      h3 {
        text-align: center;
        font-size: 1em;
        font-weight: 600;
      }
      cursor: pointer;
      outline: 0;
      user-select: none;
      border-radius: 4px;
      border-width: 1px;
      background-color: white;
      padding-left: 16px;
      padding-right: 16px;
      padding-top: 16px;
      padding-bottom: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 7em;
      font-size: 1em;
      svg {
        font-size: 1.5em;
        margin-right: 8px;
      }
    }
  }
  p {
    margin-bottom: 0.6em;
  }
  small {
    padding-left: 0 !important;
  }
}
.is-sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  box-shadow: 0 2px 18px 0 rgba(247, 206, 70, 0.2);
  background-color: #ffffff !important;
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  padding: 0.4em 0.2em;
}

.tooltip-inner {
  background-color: $white-color;
  opacity: 1;
  color: $black-color;
  font-weight: 600;
}

.tooltip-arrow {
  &::before {
    border-bottom-color: $white-color !important;
  }
}

.tooltip {
  &.overUk {
    .tooltip-inner {
      background-color: rgba(198, 223, 255, 1);
      opacity: 1;
      color: rgba(5, 106, 236, 1);
      font-weight: 600;
    }
    .tooltip-arrow {
      &::before {
        border-top-color: rgba(198, 223, 255, 1) !important;
      }
    }
  }
  &.show {
    opacity: 1;
  }
}

/**** Form Control ****/
.form-label {
  color: #000;
  font-size: 0.9em;
  font-weight: 400;
  margin-bottom: 0.2em;
}

.form-control {
  border-radius: 0.75em;
  border: 1px solid $form-border;
  background: $form-bg;
  height: 3em;
  font-size: 1em;
  &[type="file"] {
    line-height: 2.2em;
  }
  &:focus {
    outline: none;
    box-shadow: none;
    border-color: $yellow-color;
  }
}

.form-select {
  border-radius: 0.75em;
  border: 1px solid $form-border;
  background: $form-bg;
  height: 3em;
  font-size: 1em;
  padding: .375rem 1rem .375rem .75rem;
  &[type="file"] {
    line-height: 2.2em;
  }
  &:focus {
    outline: none;
    box-shadow: none;
    border-color: $yellow-color;
  }
}

.posIocn
{
  position: absolute;
    font-size: 0.9em;
    z-index: 2;
    right: 10px;
    top: 15px;
    color: $primary-color;
}

.form-pad {
  position: relative;
  .icon_pos {
    position: absolute;
    right: 0.8em;
    top: 2.3em;
  }
  .form-control {
    padding-right: 2.5em;
  }
}

.cu_icon {
  position: absolute;
  top: 11px;
  right: 18px;
}

.form-pad1 {
  position: relative;
  .icon_pos {
    position: absolute;
    right: 0.8em;
    top: 0.9em;
  }
  .form-control {
    padding-right: 2.5em;
  }
}

.formTextArea.form-control {
  border-radius: 0.75em;
  border: 1px solid $form-border;
  background: $form-bg;
  height: auto;
  font-size: 1em;
  &:focus {
    outline: none;
    box-shadow: none;
    border-color: $yellow-color;
  }
}

.searchAbs {
  position: absolute;
  top: 2.7em;
  right: 1em;
}

.login_box {
  position: relative;
  .logo1 {
    position: absolute;
    width: 9.188em;
    height: 2.938em;
    object-fit: contain;
    bottom: 1em;
    right: 1em;
  }
  .login_bg {
    border-radius: 1.5em;
    height: 59.375;
    width: 100%;
  }
  .loginBody {
    padding: 1.5em 7em;
    .logo {
      width: 9.188em;
      height: 2.938em;
      object-fit: contain;
    }
    h1 {
      margin-top: 1em;
      margin-bottom: 0.6em;
      font-weight: 700;
      font-size: 2.4em;
      color: $primary-color;
    }
    p.para {
      margin-bottom: 1.9em;
    }
  }
  &.login_boxSubs
  {
    .login_bg
    {
      height: 40em;
      object-fit: cover;
    }
  }
}

.succesMessgae {
  color: $green-color;
  text-align: center;
  margin-bottom: 1.5em;
  font-size: 1.2em;
  font-weight: 400;
}

.cardBox {
  border-radius: 1.4em;
  overflow: hidden;
  border: $white-color;
  .card-header {
    background-color: $white-color;
    padding-left: 3em;
    padding-right: 3em;
    h3 {
      margin: 0;
      margin-right: 1em;
      font-size: 1em;
      font-weight: 700
    
    }
    .form-select {
      width: 8em;
      border-radius: 100px;
      background-color: #cce0fa;
      color: #056aec;
      font-weight: 600;
      &:focus {
        box-shadow: none;
      }
    }
  }
  .card-body {
    padding-left: 3em;
    padding-right: 3em;
    h2 {
      font-size: 1em;
      margin-bottom: 1.5em;
      font-weight: 600;
    }
    h3 {
      font-size: 1.6em;
      font-weight: 600;
      margin-bottom: 0;
    }
  }
}

.cardUpgrade {
  border-radius: 1.4em;
  overflow: hidden;
  border: $white-color;
  background-color: $primary-color;
  .card-body {
    display: flex;
    align-items: center;
    .icon5 {
      width: 8.711em;
      height: 7.693em;
    }
    .logo2 {
      width: 3.693em;
      height: 3.693em;
    }
    div {
      padding: 0 1em;
      h3 {
        font-size: 1.4em;
        color: $white-color;
      }
      p {
        font-size: 0.8em;
        color: $white-color;
      }
    }
  }
}

/* Progress bar Color */
.progressBox {
  width: 50%;
  height: 50%;
  margin: 0.9em auto 0.8em;
  background-color: $bg-color;
  border-radius: 100px;
  img {
    width: 3.75em;
    height: 3.75em;
  }
}

.progressFirst,
.progressSecond,
.progressThird
{
  border-right: 1px solid #ccc;
}

.progressFirst {
  .CircularProgressbar-path {
    stroke: #38336b;
  }
  .CircularProgressbar-trail {
    stroke: $bg-color;
  }
  h3 {
    color: #38336b;
  }
}

.progressSecond {
  .CircularProgressbar-path {
    stroke: $yellow-color;
  }
  .CircularProgressbar-trail {
    stroke: $bg-color;
  }
  h3 {
    color: $yellow-color;
  }
}

.progressThird {
  .CircularProgressbar-path {
    stroke: $green-color;
  }
  .CircularProgressbar-trail {
    stroke: $bg-color;
  }
  h3 {
    color: $green-color;
  }
}

.progressFourth {
  .CircularProgressbar-path {
    stroke: $red-color;
  }
  .CircularProgressbar-trail {
    stroke: $bg-color;
  }
  h3 {
    color: $red-color;
  }
}

.tablebox {
  border-radius: 0;
  .table {
    margin-bottom: 0;
    thead {
      tr {
        th {
          background-color: $black-color;
          color: $white-color;
        }
      }
    }
  }
  tr {
    td {
      a {
        color: $primary-color;
        text-decoration: underline;
        &:hover {
          color: $black-color;
        }
      }

      .d-flex {
        a {
          color: $black-color;
          text-decoration: none;
          &:hover {
            color: $primary-color;
          }
        }
      }
    }
  }
}

.table > :not(caption) > * > * {
  padding: 1rem 1.5em;
}

.table tbody tr td {
  padding: 0.7em 1.5em;
}

thead tr:first-child {
  border-top: 0;
}

tr:first-child {
  border-top: 1;
}

tr:last-child {
  border-bottom: 0;
}

.pending {
  color: $yellow-color;
  font-weight: 700;
}

.rejected {
  color: $red-color;
  font-weight: 700;
}

.approved {
  color: $green-color;
  font-weight: 700;
}

.chartBox {
  background-color: $white-color;
  padding: 0.6em;
  border-radius: 0.5em;
  h2 {
    font-size: 1em;
    padding: 0.5em 0.9em;
    font-weight: 700;
  }
}

.apexcharts-legend,
.apexcharts-toolbar {
  display: none !important;
}

.btnPad {
  padding: 0.8em 1.2em;
}

.btn-warning {
  --bs-btn-color: #000;
  --bs-btn-bg: #f7ce46;
  --bs-btn-border-color: #f7ce46;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffca2c;
  --bs-btn-hover-border-color: #ffc720;
  --bs-btn-focus-shadow-rgb: 217, 164, 6;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffcd39;
  --bs-btn-active-border-color: #ffc720;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #f7ce46;
  --bs-btn-disabled-border-color: #f7ce46;
}

.react-datepicker__input-container input {
  height: 52px;
  border-radius: 10px;
  outline: 0;
  background-color: #ffff;
  padding: 15px 25px;
  border-width: 0;
  width: 180px;
}

.requestFlex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .rightDiv {
    flex-direction: row;
    display: flex;
    align-items: center;
    .form-select {
      width: 150px;
      height: 54px;
      border-radius: 11px;
      font-size: 0.9em;
      &:focus {
        box-shadow: none;
      }
    }
    .btnPad {
      margin-left: 1em;
      width: 100%;

      font-weight: 400;
      line-height: 0em;
    }
  }

  div.mtt20 {
    flex-direction: row;
    display: flex;
    align-items: center;
    .form-select {
      width: 150px;
      height: 54px;
      border-radius: 11px;
      font-size: 0.9em;
      &:focus {
        box-shadow: none;
      }
    }
    .btnPad {
      margin-left: 1em;
      width: 100%;

      font-weight: 400;
      line-height: 0em;
    }
  }
  .updownBox {
    margin-right: 1.5em;
    flex-direction: row;
    display: flex;
    align-items: center;
    a {
      font-size: 1.5em;
      color: #000;
      margin-right: 1em;
      &:hover {
        color: $yellow-color !important;
      }
    }
  }
}

.icon-position {
  position: fixed;
  bottom: 40px;
  right: 25px;
  z-index: 20;
}

.centerLoader
{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(100vh - 301px);
}

.loadBox{
  height: 96px;
  background-color: rgb(255, 255, 255);
  justify-content: center;
  align-items: center;
  display: flex;
  width: 100%;
  max-width: 299px;
  div{

  flex-grow: initial !important
  }
}

.icon-style {
  background-color: $primary-color;
  border: 2px solid $white-color;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  color: $white-color;
  cursor: pointer;
  animation: movebtn 3s ease-in-out infinite;
  transition: all 0.5s ease-in-out;
}

.icon-style:hover {
  animation: none;
  background: $white-color;
  color: $primary-color;
  border: 2px solid $primary-color;
}

.scheading {
  font-size: 1.6em;
  margin: 0;
  font-weight: 600;
}

.cardFlex_box {
  border-width: 0;
  .card_box_flex {
    display: flex;
    justify-content: space-between;
    .det_main {
      flex-basis: 19%;
      .det_box {
        margin-bottom: 1.5em;
      }
    }
    h3 {
      font-size: 0.8em;
      font-weight: 700;
    }
    h5 {
      font-size: 0.9em;
    }
  }
}

.cardFlex_boxshadow {
  border-width: 0;
  border-radius: 12px;
  background: #fff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
  padding: 0.7em;
}

.smallText {
  color: $primary-color;
  font-size: 0.875em;
  margin-bottom: 0.8em;
  display: block;
}

.radioBx {
  height: 3em;
  display: flex;
  align-items: center;
  .form-check-inline {
    margin-right: 2.2em;
  }
}

.w50 {
  max-width: 25em;
}

.py-3 {
  padding-top: 1.9rem !important;
}

.font14 {
  font-size: 0.9em;
  font-weight: 500;
}

.plan_box {
  .he100 {
    height: 100%;
  }
  .w30 {
    flex-basis: 30%;
  }
  .w10 {
    flex-basis: 7%;
  }
  .w25 {
    flex-basis: 25%;
  }
  .card {
    border-radius: 10px;
    background: #fff;
    padding: 0;
    overflow: hidden;
    &.borderAdvance {
      border: 0.1em solid $yellow-color;
    }
    &.borderPro {
      border: 0.1em solid $grey-color;
    }
    &.borderPrimary {
      border: 0.1em solid $primary-color;
    }
    .card-body {
      position: relative;
      padding: 2em 1em 2em 5.2em;
      flex-direction: column;
      display: flex;
      .side_badge {
        background-color: $black-color;
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 4.2em;
        &.side_badge_primary {
          background-color: #bfdfff;
        }
        h6 {
          color: $white-color;
          margin: 0;
          transform: rotate(-90deg);
          font-family: 800;
          font-size: 1.8em;
        }
        .advanceH6 {
          color: $yellow-color;
        }
        .proH6 {
          color: $white-color;
        }
        .basicH6 {
          color: $primary-color;
        }
      }
      h4 {
        font-weight: 400;
        font-size: 1em;
        color: $black-color;
        position: absolute;
        top: 0;
        right: 0;
        background-color: #f7ce46;
        padding: 5px 10px;
        color: #000000;
        font-size: 12px;
        border-bottom-left-radius: 8px;
        font-weight: 600;
      }
      h2 {
        font-weight: 600;
        font-size: 1.7em;
        color: $black-color;
        margin-bottom: 1em;
      }
      .mediaBox {
        display: flex;
        align-items: flex-start;
        margin-bottom: 0.9em;
        &:last-child {
          margin-bottom: 0;
          margin-top: auto;
        }
        img {
          margin-top: 0.1em;
        }
        div {
          margin-left: 0.7em;
          h3 {
            font-size: 1em;
            color: $grey-color;
          }
          p {
            font-size: 0.9em;
            color: $grey-color1;
            margin-bottom: 0;
          }
        }
      }
    }
  }
  .centerChange {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.flex-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  
}
.flex-row > [class*="he100"] {
  display: flex;
  flex-direction: column;
}
.flex-row.row:after,
.flex-row.row:before {
  display: flex;
}

.req_flex {
  display: flex;
  justify-content: space-between;
  .flexw {
    flex-basis: 31%;
  }
  .padNew {
    padding: 0.8em;
  }
  h2 {
    font-size: 1em;
    padding: 0.7em;
    font-weight: 600;
  }
  h3 {
    font-size: 0.9em;
  }
  h5 {
    font-size: 0.9em;
    color: $primary-color;
  }
  .request_box {
    h2 {
      background-color: rgba(255, 0, 31, 0.2);
      border-radius: 0.3em;
      text-align: center;
    }
  }
  h4 {
    height: 49px;
    display: flex;
    align-items: center;
  }
  .request_box {
    h4 {
      font-size: 1em;
      font-weight: 700;
    }
  }
  .payment_box {
    h2 {
      background-color: #bfdfff;
      border-radius: 0.3em;
      text-align: center;
      color: $primary-color;
    }
    h3 {
      text-decoration: underline;
    }
    h4 {
      font-size: 1em;
    }
  }
  .renewal_box {
    h3 {
      text-decoration: underline;
    }
    h2 {
      background-color: rgba(247, 206, 70, 0.2);
      border-radius: 0.3em;
      text-align: center;
      color: $black-color;
    }
    h4 {
      font-size: 2em;
    }
  }
}

.page-link {
  &:focus {
    box-shadow: none;
  }
  color: $black-color;
  border-color: $black-color;
}

.card_footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  h3 {
    margin: 0;
    font-size: 1em;
    color: $primary-color;
  }
}

.noti_box {
  display: flex;
  align-items: center;
  margin-bottom: 1.7em;
  &:last-child {
    margin-bottom: 0;
  }
  div {
    padding: 0 1em;
    h2 {
      color: $noti_color;
      font-size: 1em;
      font-weight: 800;
    }
    p {
      font-size: 0.9em;
      margin: 0;
      color: $noti_para_color;
      font-weight: 800;
    }
  }
}

.auth_settings {
  color: $primary-color;
  text-decoration: underline;
  display: block;
  font-size: 1em;
  font-weight: 800;
}

.upload_box {
  border-radius: 12px;
  border: 1px solid #d4d7e3;
  background: #f7fbff;
  position: relative;
  .logoicon {
    width: 100%;
    height: 10em;
    object-fit: contain;
    padding: 2em;
  }
  .cam_pos {
    position: absolute;
    bottom: 0;
    right: -22px;
    z-index: 2;
  }
}

.reset_title {
  color: $primary-color;
  font-size: 1.5em;
  font-weight: 700;
}

.contact_us {
  position: relative;
  .flex_get {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .wi50 {
    width: 49%;
  }
  .get_bgimg {
    img {
      width: 100%;
      object-fit: cover;
      height: calc(100% - 0em);
    }
  }
  .fixHeight {
    height: calc(100% - 0em);
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 6em;
    padding-right: 6em;
  }
  .logo {
    width: 10.063em;
    height: 3.125em;
  }
  h2 {
    font-size: 2em;
    font-weight: 700;
    color: $primary-color;
    margin: 1.2em 0 0.7em;
  }
  p {
    margin: 0 0 1.3em;
  }
  .btn-primary {
    background-color: #056aec !important;
    border-color: #056aec!important;
  }
}

.dark {
  background-color: #333;
  color: #fff;
}

.overview_sec {
  .scheading {
    font-size: 1.2em;
    color: $primary-color;
  }
}

.tab_link {
  margin-bottom: 2.5em;
  a {
    padding: 0.5em 1em;
    display: inline-block;
    border: 1px solid $primary-color;
    border-radius: 10px;
    background-color: $white-color;
    font-size: 0.87em;
    color: $primary-color;
    margin-right: 0.3em;
    transition: all ease 0.3s;
    margin-bottom: 0.5em;
    &.active {
      background-color: $primary-color;
      color: $white-color;
    }
    &:hover {
      background-color: $black-color;
      color: $white-color;
      border-color: $white-color;
    }
    svg {
      margin-right: 0.4em;
    }
  }
}

.accordion {
  .accordion-item {
    border-radius: 0.5em;
    margin-bottom: 1.5em;
    .accordion-header {
      border-radius: 0.5em !important;
      button {
        font-weight: 700;
      }
    }
    .accordion-body {
      padding-top: 0.2em;
      padding-bottom: 0.2em;
    }
    .accordion-button {
      &:focus {
        box-shadow: none;
      }
      &:not(.collapsed) {
        box-shadow: none;
        background-color: transparent;
      }
    }
    &:first-of-type,
    &:last-of-type {
      .accordion-button {
        border-radius: 0.5em;
      }
    }
  }
}

.card-boxx {
  border: 0;
  &.card-boxx1 {
    .repeat_sec {
      div {
        margin: 0 0.6em 0 0;
      }
    }
  }
  .repeat_sec {
    display: flex;
    justify-content: space-between;
    align-items: center;
    div {
      flex-grow: 1;
      margin: 0 1em;
      &.wfx {
        width: 0%;
      }
    }

    svg {
      cursor: pointer;
      &.delete {
        path {
          fill: $primary-color;
          opacity: 1;
        }
        &:hover {
          path {
            opacity: 1;
            fill: $red-color;
          }
        }
      }
    }
  }
  &.card-boxx_mar {
    .repeat_sec {
      div {
        margin: 0 1em 0 0;
      }
    }
  }
  
}

.repeat_secpos {
  position: relative;
  display: flex;
  align-items: center;
  svg {
    cursor: pointer;
    &.delete {
      path {
        fill: $primary-color;
        opacity: 1;
      }
      &:hover {
        path {
          opacity: 1;
          fill: $red-color;
        }
      }
    }
  }
}

.card_Block {
  border: 0;
  .card-header {
    background-color: $primary-color;
    padding: 1em 1.4em;
    color: $white-color;
  }
  .card-body {
    padding: 1.7em 1.4em;


    .table {
      margin-bottom: 0;
      vertical-align: middle;
      thead {
        tr {
          th {
            font-size: 13px;
            border-bottom-color: #f0f0f0;
           
            padding-bottom: 0.4em;
            strong {
              color: $primary-color;
            }
            span {
              font-size: 0.875em;
              
            }
          }
          // &:last-child {
          //   td {
          //     padding-bottom: 0;
          //   }
          // }
        }
      }
      tbody {
        tr {
          margin-bottom: 2em;
          &:last-child {
            margin-bottom: 0;
          }
          td {
            border-bottom-color: #f0f0f0;
          
            // padding-bottom: 2.4em;
            font-size: 13px;
            strong {
              color: $primary-color;
            }
            img {
              width: 2em;
             
            }
          }
          // &:last-child {
          //   td {
          //     padding-bottom: 0;
          //   }
          // }
        }
      }
      &.cont_table {
        tr {
          th {
            text-align: right;
          }
          td {
            padding-bottom: 0.9em;
          }
        }
      }

      &.cont_table1 {
        tr {
          th {
            font-size: 0.9em;
            color: $black-color;
          }
          td {
            font-size: 0.9em;
            padding-bottom: 0.8em;
            .headingText {
              color: $placeholder-color;
              font-size: 1.1em;
            }
            img {
              width: 1.5em;
            }
            &:last-child {
              img {
                width: 3em;
              }
            }

            .borderRight {
              display: block;
              border-left: 2px solid rgba(34, 37, 40, 0.31);
              border-right: 2px solid rgba(34, 37, 40, 0.31);
            }
            .borderRight1 {
              display: block;
              border-right: 2px solid rgba(34, 37, 40, 0.31);
            }
            .borderRightNew {
              display: block;
              border-right: 2px solid rgba(34, 37, 40, 0.31);
            }
          }
        }
      }
    }
  }

  &.card_BlockBordeColor {
    .card-body {
      border: 8px solid #e6e6e6;
      border-radius: 10px;
      margin-top: 0.9em;
    }
  }

  &.card_BlockBordeColor1 {
    .card-body {
      border: none;
      border-radius: 0;
      margin-top: 0;
      padding: 1.4em 1em;
    }
    .cont_table1 {
      tr {
        border: 8px solid #e6e6e6;
        border-radius: 10px;

        th,
        td {
          text-align: center;
          padding: 1.2em 0 !important;
        }
        td {
          strong {
            color: $black-color !important;
          }
        }
      }
    }
  }
}

@media (min-width: 991px) {
  .container, .container-lg, .container-md, .container-sm, .container-xl {
      max-width: 1340px;
  }
}

.btnBack {
  background-color: $white-color;
  border: 1px solid $primary-color;
  color: $primary-color;
  padding: 0.6em 1em;
  &:hover {
    background-color: $black-color;
    border-color: $black-color;
    color: $white-color;
  }
  svg {
    margin-right: 0.5em;
  }
}

.borderRight {
  display: block;
  border-left: 2px solid rgba(34, 37, 40, 0.31);
  border-right: 2px solid rgba(34, 37, 40, 0.31);
}

.s15 {
  font-size: 1.1em;
  font-weight: 300;
  span {
    color: #ff4343;
  }
}

.radioBxNew {
  .form-check {
    margin-left: 0 !important;
  }
}

/******* Landing Page ********/

.banner_sec {
  h1 {
    font-size: 3em;
    font-weight: 700;
    margin-bottom: 0.3em;
    span {
      color: $yellow-color;
    }
  }
  h4 {
    font-size: 1.2em;
    margin-bottom: 0.5em;
  }
  h5 {
    font-size: 1.1em;
    margin-bottom: 1.3em;
    color: $primary-color;
    span {
      color: $yellow-color;
      font-weight: 600;
    }
  }
  ul {
    padding-left: 17px;
    text-align: left;
  }
  p {
    font-size: 1.5em;
    opacity: 0.6;
    margin: 1em 0 1.5em;
  }
  .btn-primary {
    width: 100%;
    background-color: $yellow-color;
    border-color: $yellow-color;
    max-width: 10em;
    color: $black-color;
    &:hover {
      border-color: $black-color;
      background-color: $black-color;
      color: $white-color;
    }
  }
}

.light_heading {
  font-size: 2em;
  font-weight: 700;
  margin-bottom: 0.6em;
  color: $black-color !important;
  span {
    color: $yellow-color;
  }
}

.aboutlanding_sec {
  h2 {
    font-size: 2em;
    font-weight: 700;
    color: $primary-color;
  }
  p {
    width: 100%;
    max-width: 32em;
    margin: 1.5em auto 2em;
    opacity: 0.6;
  }

  .btn-primary {
    margin-top: 2em;
    width: 100%;
    background-color: $yellow-color;
    border-color: $yellow-color;
    max-width: 10em;
    color: $black-color;
    img {
      margin-left: 0.5em;
    }
    &:hover {
      border-color: $black-color;
      background-color: $black-color;
      color: $white-color;
    }
  }
}

.featureland_sec {
  .light_heading {
    margin-bottom: 1.5em;
  }
  img {
    margin-bottom: 2em;
  }
  h3 {
    font-size: 1.4em;
    font-weight: 800;
  }
  p {
    font-size: 1em;
    opacity: 0.6;
    max-width: 13em;
    width: 100%;
    margin: 0 auto;
  }
}

.howitwork_sec {
  .light_heading {
    margin-bottom: 1.5em;
  }
  svg {
    margin-bottom: 2em;
    width: 100%;
  }
  h3 {
    font-size: 1.4em;
    font-weight: 800;
    color: $primary-color;
    margin-bottom: 0.6em;
  }
  p {
    font-size: 1em;
    opacity: 0.6;
    max-width: 13em;
    width: 100%;
    margin: 0 auto;
  }
}

.radio_box {
  display: flex;
  justify-content: center;
  .panel {
    .panel-heading {
      padding: 0.6em 2.5em;
    }
  }
  .border_b {
    border: 1px solid $black-color;
    overflow: hidden;
    border-radius: 5px;
    background-color: $white-color;
  }
  .card-input-element {
    display: none;
    &:checked {
      + {
        .card-input {
          background-color: $black-color;
          color: $yellow-color;
        }
      }
    }
  }
  .card-input {
    &:hover {
      cursor: pointer;
    }
  }
}

.plan_sec {
  .plan_box {
    .card {
      .card-body {
        h2 {
          span {
            font-size: 0.7em;
            color: $primary-color;
          }
          &.advnce {
            color: $yellow-color;
            span {
              color: $yellow-color;
            }
          }
        }
      }
    }
    .w30 {
      flex-basis: 35%;
    }
  }
}

.btn-primary {
  &.btn-yellow {
    background-color: #f7ce46;
    border-color: #f7ce46;
    color: $black-color;
    transition: all 0.3s ease;
    img {
      margin-left: 0.5em;
    }
    &:hover {
      background-color: $black-color;
      border-color: $black-color;
      color: $white-color;
    }
  }
}

.maxWidthAuto {
  width: 100%;
  max-width: 10em;
}

.slick-slide {
  img {
    filter: brightness(0.2);
    width: 10em;
    height: 2em;
    object-fit: contain;
  }
}

.logo_border {
  padding: 2.5em 0;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  margin-bottom: 1.8em;
}

.blog_sec {
  .blog_card {
    h2 {
      font-size: 1.2em;
    }
    p {
      font-size: 1em;
      opacity: 0.6;
      max-width: 13em;
      width: 100%;
      margin: 1em 0 0.5em;
    }
    svg {
      font-size: 1.5em;
      path {
        fill: $yellow-color;
      }
    }
  }
}

.footer {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  padding: 2em 0;
  position: relative;
  &::before {
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.9);
  }
  .container-fluid {
    position: relative;
  }
  .footLogo {
    width: 13em;
    margin-bottom: 1.4em;
  }
  .foot_ab {
    color: $white-color;
    opacity: 0.8;
  }
  h3 {
    font-size: 1.2em;
    color: $yellow-color;
    font-weight: 600;
    margin-bottom: 0.6em;
  }
  ul {
    list-style: none;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    li {
      margin-right: 1.2em;
      margin-bottom: 0.5em;
      border-right: 1px solid $grey-color1;
      padding-right: 1.2em;
      &:last-child {
        border-right: 0;
      }
      a {
        color: $grey-color1;
        &:hover,
        &.active {
          color: $yellow-color;
        }
      }
    }
  }

  .copyright {
    text-align: right;
    color: $white-color;
    font-size: 0.9em;
    p {
      margin: 0;
    }
  }

  .social_links {
    a {
      margin-right: 2em;
      &:last-child {
        margin-right: 0;
      }
    }
  }

  .expFlex {
    display: flex;

    .swtich_box {
      padding-top: 0.5em;
      h4 {
        color: $yellow-color;
        font-size: 0.7em;
      }
    }
  }
}

._2Jtxm ._2kL0S,
._2Jtxm ._2JvrO {
  height: 1.2rem;
  width: 1.2rem;
  background-color: $primary-color;
  color: white;
  animation: _JAh3L 400ms ease forwards;
  border: 0px solid #387cf6;
  color: transparent;
}

._2Jtxm._2ZUAI ._2JvrO {
  height: 1.2rem;
  width: 1.2rem;
  background-color: $primary-color;
  color: white;
  animation: _JAh3L 400ms ease forwards;
  border: 0px solid #387cf6;
  color: transparent;
  svg {
    opacity: 0;
  }
}

._2_g61 {
  padding-right: 0;
  padding-left: 0;
}
._1Lo2h {
  ._2Jtxm {
    ._1hzhf {
      top: -30px;
      color: $grey-color;
      font-size: 0.7em;
      width: 100%;
    }
    &::before {
      height: 2px;
      background: $primary-color;
    }

    &._35Ago {
      ._1hzhf {
        font-weight: 600;
        color: $black-color;
        width: 100%;
      }
    }
    &._35Ago,
    &._2ZUAI {
      &::before {
        content: "";
        position: absolute;
        top: 50%;
        height: 2px;
        width: 100%;
        right: 0%;
        transform: translateY(-50%);
        z-index: -1;
        background: $primary-color;
        transition: background-position 200ms ease-in-out;
      }
      ._2kL0S {
        background-color: #ffffff;
        color: white;
        animation: _JAh3L 400ms ease forwards;
        border: 3px solid #387cf6;
      }
    }
    &:last-child {
      &::after {
        content: "";
        position: absolute;
        top: 50%;
        height: 2px;
        width: 100%;
        right: 0;
        transform: translateY(-50%);
        z-index: -1;
        background: $primary-color;
        transition: background-position 200ms ease-in-out;
      }
    }
  }
}

._2pGos {
  padding: 0.75rem 4rem;
  height: 3.25em;
  border-radius: 0.75em;
  font-weight: 600;
  background-color: $primary-color;
  color: white;
}

._hsN1w {
  &:hover {
    color: $white-color;
  }
}

._2pGos._2RWmX {
  background-color: transparent;
  color: $black-color;
  border-color: $black-color;
}

.width_btn {
  width: 100%;
  max-width: 18em;
}

.rpsHeading {
  color: $primary-color;
  font-size: 1.25em;
  font-weight: 700;
  margin: 1em 0 1.5em;
}

.noRisk {
  font-size: 1em;
  color: $green-color;
}

.deleteBbox {
  display: flex;
  align-items: center;
  svg {
    position: relative;
    top: 5px;
  }
}
.cxBox {
  display: flex;
  justify-content: center;
  margin-top: 18px;
  span,
  div {
    width: 5em;
    display: inline-block;
    text-align: center;
  }
}

.newtabLe {
  padding: 8px 4px;
  background-color: rgba(0, 0, 0, 0.1);
  .table {
    margin-bottom: 0;
    tr {
      border-width: 4px;
    }
  }
}

.itaLic {
  font-style: italic;
}

.it_box {
  label {
    span {
      margin-left: 0.2em;
      cursor: pointer;
      padding: 0 0.8em;
    }
  }
}

.checkBtn_box {
  text-align: right;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  .checkBtn {
    background-color: rgba(5, 106, 236, 0.14);
    padding: 1em 1.5em;
    border-radius: 100px;
    font-weight: 600;
    font-size: 0.9em;
    display: inline-block;
  }
}

.breadcrumb_Box {
  position: relative;
  height: 18em;
  display: flex;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  .container {
    position: relative;
    z-index: 2;
  }
  h1 {
    margin: 0 0 0.2em;
    text-align: center;
    color: $white-color;
  }
  &::before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.6);
    height: 100%;
    width: 100%;
  }
  .breadcrumb {
    justify-content: center;
    margin: 0;
    .breadcrumb-item {
      a {
        color: $yellow-color;
      }
      color: $white-color;
    }
  }
}

.breadcrumb-item + .breadcrumb-item::before {
  color: #fff;
}

.about_sec1 {
  h3 {
    font-size: 1em;
    color: $primary-color;
  }
  small {
    color: $primary-color;
  }
  h2 {
    font-size: 1em;
    margin: 0 0 1em;
    font-weight: 400;
    color: $black-color;
  }
  .about-funfact {
    .number {
      font-size: 2.5em;
      line-height: 1;
      color: $yellow-color;
      margin-bottom: 0.4em;
    }
    .text {
      font-size: 1.4em;
      line-height: 1.5;
      margin-bottom: 0.4em;
    }
  }
}

textarea.form-control {
  height: auto;
}

.contact_usbox {
  h2 {
    font-weight: 600;
    margin-bottom: 1em;
  }
  p {
    font-size: 1em;
    margin-bottom: 1.5em;
    opacity: 0.8;
  }
  ul {
    margin: 0;
    padding: 0;
    li {
      border-bottom: 1px solid #e1e1e1;
      padding-bottom: 15px;
      margin-bottom: 15px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      font-weight: 600;
      svg {
        margin-right: 0.8em;
      }
      &:last-child {
        border-bottom: none;
        margin-bottom: 0;
        padding-bottom: 0;
      }
    }
  }
}

.blog {
  overflow: hidden;
  height: 100%;
  -webkit-transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);

  .thumbnail {
    position: relative;
    overflow: hidden;
    .image {
      display: block;
      img {
        width: 100%;
        -webkit-transition: all 1.5s cubic-bezier(0, 0, 0.2, 1);
        transition: all 1.5s cubic-bezier(0, 0, 0.2, 1);
      }
    }
  }
  &:hover {
    .thumbnail {
      .image {
        img {
          -webkit-transform: scale(1.1);
          transform: scale(1.1);
        }
      }
    }
    .info {
      .arrows_b {
        svg {
          path {
            color: $primary-color;
          }
        }
      }
      .title {
        a {
          color: $primary-color;
        }
      }
    }
  }
  .info {
    position: relative;
    padding: 1.5em 0;
    .meta {
      font-size: 0.875em;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      margin-bottom: 0.9em;
      padding-left: 0;
      list-style: none;
      justify-content: space-between;
      li {
        display: flex;
        align-items: center;
        font-weight: 600;
        svg {
          margin-right: 0.3em;
        }
      }
    }
    .title {
      font-size: 1.4em;
      line-height: 1.3;
      margin-bottom: 0.2em;
      font-weight: 800;
      a {
        color: $black-color;
        transition: all ease 0.3s;
      }
    }
    .arrows_b {
      font-size: 1.8em;
      transition: all ease 0.3s;
      svg {
        path {
          transition: all ease 0.3s;
          color: $yellow-color;
        }
      }
      &:hover {
        svg {
          path {
            color: $primary-color;
          }
        }
      }
    }
  }
}

.blog_sec {
  .pagination {
    justify-content: center;
    .page-item {
      .page-link {
        padding: 0.6em 1.2em;
        border-color: #dee2e6;
      }
    }
  }
}

.blog_details {
  h1 {
    margin: 0.4em 0 0.5em;
  }
  h3 {
    font-size: 1.5em;
    font-weight: 600;
  }
  h4 {
    font-size: 0.8em;
  }
  .blog_side {
    h3 {
      font-size: 1.5em;
      font-weight: 600;
      margin-bottom: 0.7em;
    }
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      li {
        margin-bottom: 1em;
        padding-bottom: 1em;
        border-bottom: 1px solid #f3f3f3;

        h4 {
          font-size: 1.1em;
          opacity: 0.8;
        }
        a {
          font-size: 1.3em;
          color: $black-color;
          &:hover {
            color: $primary-color;
          }
        }
      }
    }
  }
}

.termscontent {
  h2 {
    font-size: 24px;
    font-weight: 700;
    color: #212121;
    position: relative;
    border-bottom: 1px solid #e3e3e3;
    padding-bottom: 5px;
    margin-bottom: 10px;
    &:after {
      position: absolute;
      content: "";
      bottom: 0px;
      left: 0px;
      height: 0.1em;
      width: 2.5em;
      background: $yellow-color;
    }
  }
}

.navbar.bg-primary {
  background-color: $white-color !important;
}

button.uploadBtn.btn.btn-primary {
  width: auto;
  margin-left: 1em;
}

.textDeco {
  text-decoration: underline;
}

button.uploadBtn.uploadBtn1.btn.btn-primary {
  width: auto;
  margin: 0 1.9em;
}

.arrowUpDown {
  text-align: right;
  svg {
    cursor: pointer;
    &:first-child {
      margin-right: 0.6em;
    }
    &:hover {
      path {
        fill: $yellow-color;
      }
    }
  }
}

.graynew {
  background-color: $form-bg;
}

.slider_new {
  .imgAfter {
    position: relative;
    // &::before {
    //   position: absolute;
    //   content: "";
    //   top: 0;
    //   left: 0;
    //   right: 0;
    //   bottom: 0;
    //   width: 100%;
    //   height: 100%;
    //   background-color: rgba(0, 0, 0, 0.7);
    // }
    .bigImg {
      width: 100%;
      object-fit: contain;
    }
  }

  .carousel-caption {
    color: #fff;
    text-align: center;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 50%;
    padding: 0;
    z-index: 2;
    bottom: auto;
    right: auto;
    h3,
    p {
      color: $white-color;
    }
    p {
      margin: 0;
    }
    h3 {
      font-size: 3em;
      font-weight: 600;
      text-transform: capitalize;
    }
  }
}

.light {
  .navbar.bg-primary {
    background-color: $white-color !important;
    &.afterLogin {
      background-color: $black-color !important;
    }
  }

  .navbar {
    .navbar-brand {
      img {
        height: 65px;
      }
    }
  }

  .darkLogo {
    display: none;
  }

  .navbar {
    .navbar-nav {
      &.padTabLink {
        .nav-link {
          color: $white-color;
          padding: 0.6em 0.4em;
          font-size: 0.76em;
          display: flex;
          align-items: center;
          margin-right: 0.5em;
          svg {
            width: 16px;
            margin-right: 0.4em;
          }
        }
      }
      .nav-link {
        font-weight: 500;
        color: $black-color;
        padding: 0.6em 0.6em;
        &:hover,
        &.active {
          color: $yellow-color;
          background-color: transparent;
        }
        &.joinBtn {
          background-color: $yellow-color;
          padding: 0.6em 1.2em;
          img {
            margin-left: 0.4em;
          }
          &:hover {
            color: $black-color;
            opacity: 0.6;
          }
        }
      }
    }
    .navbar-toggler {
      border-color: $black-color;
    }
    .navbar-toggler-icon {
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox=%270 0 32 32%27 xmlns=%27http://www.w3.org/2000/svg%27%3E%3Cpath stroke=%27rgba%280,0,0, 1%29%27 stroke-width=%272%27 stroke-linecap=%27round%27 stroke-miterlimit=%2710%27 d=%27M4 8h24M4 16h24M4 24h24%27/%3E%3C/svg%3E");
    }
  }
}

.dark {
  background-color: $dark_color !important;
  .navbar.bg-primary {
    background-color: $dark_color !important;
  }
  .logo_border {
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  }

  .termscontent {
    h2 {
      color: $white-color;
    }
  }

  .slick-slide {
    img {
      filter: brightness(1);
    }
  }

  .lightLogo {
    display: none;
  }

  .navbar {
    .navbar-nav {
      .nav-link {
        font-weight: 500;
        color: $white-color;
        padding: 0.6em 0.6em;
        &:hover,
        &.active {
          color: $yellow-color;
          background-color: transparent;
        }
        &.joinBtn {
          background-color: $yellow-color;
          padding: 0.6em 1.2em;
          color: $black-color;
          img {
            margin-left: 0.4em;
          }
          &:hover {
            color: $black-color;
            opacity: 0.6;
          }
        }
      }
    }
  }

  .light_heading {
    color: $white-color !important;
  }

  .howitwork_sec {
    svg {
      :nth-child(2) {
        fill: $primary-color;
      }
    }
    h3 {
      color: $yellow-color;
    }
  }

  .radio_box {
    .border_b {
      border: 1px solid $black-color;
      background-color: $white-color;
    }
    .panel {
      .panel-heading {
        color: #000;
      }
    }
    .badge {
      &.bg-primary {
        background-color: $yellow-color !important;
        color: $black-color !important;
      }
    }
    .card-input-element {
      display: none;

      &:checked {
        + {
          .card-input {
            .panel-heading {
              color: $white-color;
            }
            background-color: $black-color;
            color: $yellow-color;
          }
        }
      }
    }
  }

  .plan_sec {
    .plan_box {
      .card {
        background-color: $dark-color;
        .card-body {
          h2 {
            color: $white-color;
            span {
              color: $grey-color;
            }
            &.advnce {
              color: $yellow-color;
              span {
                color: $yellow-color;
              }
            }
          }
        }
      }
    }
  }

  .form-label {
    color: $white-color !important;
  }

  .blog {
    .info {
      .title {
        a {
          color: $white-color;
          &:hover {
            color: $yellow-color;
          }
        }
      }
    }
  }

  .blog_details {
    .blog_side {
      ul {
        li {
          border-bottom: 1px solid #414141;
          a {
            color: $white-color;
            &:hover {
              color: $yellow-color;
            }
          }
        }
      }
    }
  }

  .about_sec1 {
    h2 {
      color: $white-color;
    }
  }
}

.textUnderline {
  text-decoration: underline;
}

.crBox {
  height: 57px;
  display: flex;
  align-items: center;
}

img.img-fluid.robotCss {
  position: absolute;
  bottom: -38px;
  right: 75px;
  @media (max-width: 767px) {
    display: none;
  }
}

.table_Box {
  vertical-align: middle;
  margin-bottom: 0;
  & > :not(caption) > * > * {
    border-bottom-width: 0;
  }
  thead {
    tr {
      th {
        padding: 0.6em 1.1em;
        font-size: 0.875em;
      }
    }
  }
  tbody {
    tr {
      td {
        padding: 0.7em 1.1em;
      }
    }
  }
  .crBox {
    height: auto;
    display: flex;
    align-items: center;
  }

  &.table_Box12 {
    thead {
      tr {
        th {
          padding: 0.6em 0.1em;
        }
      }
    }
    tbody {
      tr {
        td {
          padding: 0.7em 0.1em;
        }
      }
    }
  }
}

.batch_tabs {
  a {
    background-color: $white-color;
    padding: 0.6em 1em;
    border-radius: 7px;
    font-size: 0.875em;
    border: 1px solid $primary-color;
    transition: 0.25s ease-out;
    margin-right: 0.8em;
    display: inline-block;
    &:hover {
      background-color: $primary-color;
      color: $white-color;
    }
    &.active {
      background-color: $primary-color;
      color: $white-color;
    }
  }
}

.batch_badge {
  color: $black-color;
  svg {
    position: relative;
    top: -1px;
  }
}

.table-responsive::-webkit-scrollbar-track {
  background-color: #dfdfdf;
}

.table-responsive::-webkit-scrollbar {
  width: 3px;
  height: 10px;
  background-color: #f5f5f5;
}

.table-responsive::-webkit-scrollbar-thumb {
  background-color: #000000;
  border-radius: 10px;
}

.btnPadNew {
  font-size: 0.875em;
  padding: 0.6em;
  margin-right: 0.4em;
}

.orHeading {
  font-weight: 600;
  display: block;
  margin-bottom: 0.4em;
}

.scree_btn {
  &.w-100 {
    .mb-3 {
      width: 100%;
    }
  }
  &.scree_btnShrink
  {
    .form-pad
    {
      flex-shrink: 0;
    }
  }
}

.upload-btn-wrapper {
  position: relative;
  overflow: hidden;

 input[type=file] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
  }
}

.widthSmall
{
  position: relative;
}

.btn {
  &.maxcontent
  {
    width: fit-content;
  }
 
  &.wfix{
    width: fit-content;
  }
}

.scree_btn
{
  &.scree_btn1
  {
    .mb-3{
      margin-bottom: 0 !important
    }
  }
}
.modal-container {
  position: absolute; /* or absolute */
  background-color: white;
  z-index: 1000;
  max-height: 51vh; /* Adjust the maximum height as needed */
  overflow-y: auto; /* Enable vertical scrolling if needed */
  padding: 10px;
  top: 84px;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  width: 100%;
  max-width: 315px;
  list-style: none;
  li{
    font-size: 0.875em;
    &:not(:last-child){
      border-bottom: 1px solid #f0ecec;
      padding-bottom: 7px;
      margin-bottom: 7px;
    }
  }
}

// ._3uApM
// {
//   a:nth-child(2)
//   {
//     display: none;
//   }
// }

// .nBtn
// {
//   padding: 0.75rem 4rem;
//     height: 3.25em;
//     border-radius: 0.75em;
//     font-weight: 600;
//     background-color: #056aec;
//     color: white;    
//     border: 1px solid #397cf6;
//     position: absolute;
//     bottom: 31px;
//     right: 0
// }

// .disableButton
// {
//   ._2pGos{
//     opacity: 0.7;
//   }
// }

.rocky{
  height: 20px;
  background-color: red;
}

.disabledButton {
  background-color: #ccc; /* Set a background color for disabled state */
  color: #666; /* Set a text color for disabled state */
  cursor: not-allowed; /* Change cursor to not-allowed */
}
.searchbtnmain_div {
  display: flex;
  align-items: flex-end;
  margin-bottom: 10px;
  .form-pad {
    flex-grow: 1;
    margin-bottom: 0px!important;
}
button.search_btn {
  display: inline-block;
  width: auto;
  min-width: 120px;
  flex-shrink: 0;
  margin-left: 10px;
}


}
.notes {
  border: 1px solid #dedede;
  padding: 15px;
}

.wWidth
{
  width: 70%;
}

.modalTopNew {
  position: absolute;
  background-color: white;
  z-index: 1000;
  max-height: 51vh; /* Adjust the maximum height as needed */
  overflow-y: auto; /* Enable vertical scrolling if needed */
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  width: 100%;
  top: 50px;
  max-width: 315px;
  list-style: none;
  li{
    font-size: 0.875em;
    &:not(:last-child){
      border-bottom: 1px solid #f0ecec;
      padding-bottom: 7px;
      margin-bottom: 7px;
    }
  }
}

.form-check-input
{
  --bs-border-width:2px;
  &:checked{
    --bs-border-width:1px;
  }
}
.custom-select {
  appearance: none !important; /* Removes default styles */
  padding-right: 30px; /* Space for the icon */
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2212%22%20height%3D%2212%22%20viewBox%3D%220%200%2012%2012%22%3E%3Ctitle%3Edown-arrow%3C%2Ftitle%3E%3Cg%20fill%3D%22%23000000%22%3E%3Cpath%20d%3D%22M10.293%2C3.293%2C6%2C7.586%2C1.707%2C3.293A1%2C1%2C0%2C0%2C0%2C.293%2C4.707l5%2C5a1%2C1%2C0%2C0%2C0%2C1.414%2C0l5-5a1%2C1%2C0%2C1%2C0-1.414-1.414Z%22%20fill%3D%22%23056aec%22%3E%3C%2Fpath%3E%3C%2Fg%3E%3C%2Fsvg%3E");
  background-repeat: no-repeat;
  background-position: right 12px top 47%;
}

.custom-select::-ms-expand {
  display: none; /* Hides default arrow in IE/Edge */
}
.noteBox{
  margin-bottom: 16px;
  margin-top: -6px;
  font-size: 0.875em;
  font-weight: 600;
  span{
    color: red;
  }
  color: #606264;
}

.tooltip {
  background-color: #aeaeae;
  padding: 2px;
  border-radius: 10px;
  .tooltip-arrow{
    &::before{
      border-right-color:#aeaeae !important
    }
  }
  .tooltip-inner
  {
    background-color: #aeaeae;
    color: #ffffff;
    text-align: left;
    padding: 6px 10px 8px;
  }
 
}
.allCards {
  margin-bottom: 31px;
  display: flex;
  flex-direction: column;
  row-gap: 17px;
  .form-check-reverse{
    text-align: left;

  }
}

.css-13cymwt-control,
.css-t3ipsp-control
{
  min-height: 48px !important;
  border-radius: 0.75em !important;
  border-color: #d4d7e3 !important;
  &:focus{
    border-color: $yellow-color !important
  }
}

.widthSmall
{
  .table thead tr th,
  .table tbody tr td{
    font-size: 13px;
    border-bottom-color: #f0f0f0
  }

}


/* Media Quary */
@import "./component/sass/MediaQuary.scss";
